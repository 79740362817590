<template>
  <section class="intro">
    <div class="intro-content">
      <h1>{{ $t('intro.headline') }}</h1>

      <div class="intro-text">
        <v-markdown :source="$t('intro.content')" />
      </div>
      <v-button router-link as="a" :to="{ path: thesesPath }">
        {{ $t('intro.button') }}
        <feather-check-circle />
      </v-button>
      <div style="margin-top: var(--base-gap)">
        <div style="margin-bottom: 12px"><strong>{{ $t('intro.developed_by') }}:</strong></div>
        <div class="list-wrapper">
          <div v-for="team of teamLabels" :key="team.label" :class="`people-group ${team.token}`">
            <a :href="team.url" target="_blank" rel="noopener noreferrer" class="group-inner">
              <img v-if="team.logo" :class="`team-logo ${team.token}`" :src="team.logo" :alt="team.label" />
              <span :hidden="team.logo !== false">{{ team.label }}</span>
            </a>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import { getTranslatedUrl } from '@/i18n/helper'
export default {
  name: 'Intro',

  components: {
    'feather-check-circle': () =>
      import('vue-feather-icons/icons/CheckCircleIcon' /* webpackChunkName: "icons" */),
  },

  data() {
    return {
      teamLabels: [
        {
          token: 'foederalist',
          label: 'Der (europäische) Föderalist',
          logo: require('@/assets/foederalist-logo.png'),
          url: 'https://www.foederalist.eu',
        },
        {
          token: 'polis',
          label: 'Polis180',
          logo: require('@/assets/svg/polis-colored-logo.svg'),
          url: 'https://polis180.org/',
        },
        {
          token: 'poe',
          label: 'Pulse of Europe',
          logo: require('@/assets/svg/poe-colored-logo.svg'),
          url: 'https://pulseofeurope.eu',
        },
      ],
    }
  },
  computed: {
    thesesPath() {
      return getTranslatedUrl('theses')
    },
  },
}
</script>

<style lang="postcss" scoped>
h1,
p {
  margin-bottom: var(--base-gap);
}

p.small {
  font-size: var(--font-size-small);
}

.intro {
  position: relative;
}

.intro-content {
  position: relative;
  z-index: 1;
}

.intro-text {
  margin-bottom: var(--base-gap);
}

.group-inner {
  display: flex;
  flex: 1;
  justify-content: center;
  overflow: hidden;
}

.people-group {
  flex-basis: calc(33.333% - 16px);
  padding: 12px;
  background: white;
  align-self: stretch;
  align-content: center;
  border-radius: var(--border-radius);

  @media (max-width: 576px) {
    flex-basis: 100%;
    height: 72px;
  }
}

.team-logo {
  max-height: 50px;
  object-fit: contain;
}

.list-wrapper {
  display: flex;
  flex-flow: row wrap;
  gap: 24px;

  @media (max-width: 576px) {
    flex-flow: row wrap;
  }
}
</style>
